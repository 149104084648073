.acessibilidade .panel {
    background-color: #000;
}
.acessibilidade .list-group-item {
    background-color: #131313;
}

.acessibilidade .controle-acessibilidade{
    background-color: #000;
    -webkit-box-shadow: 1px 5px 10px 1px #4D4D4D;
    box-shadow: 1px 5px 10px 1px #4D4D4D;
}
/*.controle-acessibilidade a:focus {
  border: 1px solid #268695;
}*/
.controle-acessibilidade{
    float: left;
    /*width: 155px;*/

    background-color: #024d29;
    border-radius: 6px 6px 6px 6px;
    float: left;
    height: auto;
    line-height: 0px;
    margin-top: 6px;
    padding: 5px 7px;
    -webkit-box-shadow: 1px 5px 10px 1px #00440d;
    box-shadow: 1px 5px 10px 1px #00440d;
}
.controle-acessibilidade .controle-font
, .controle-acessibilidade .controle-contraste {
    float: left;
    height: 15px;
    margin-right: 6px;
}
.controle-acessibilidade .controle-font a{
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    line-height: normal;
    font-weight: bold;
    font-size: 12px;
    float: left;
    width: 18px;
    text-align: center;
}
.controle-acessibilidade .controle-font a.fonte-normal{
    opacity: 0.6;
    cursor: default;
}
.controle-acessibilidade .controle-contraste a{
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    line-height: normal;
    font-weight: bold;
    margin-top: 1px;
    font-size: 12px;
    display: block;
}


@media (max-width: 768px){
    .controle-acessibilidade,
    .acessibilidade .controle-acessibilidade {
        background-color: transparent !important;
        -webkit-box-shadow: 1px 5px 10px 1px transparent !important;
        box-shadow: 1px 5px 10px 1px transparent !important;
    }
    .acessibilidade .box-config-toggle {
        background-color: #0f0f0f;
        border: 2px solid #5A5A5A;
    }
    .acessibilidade .box_botoes_acessibilidade {
        -webkit-box-shadow: 1px 5px 10px 1px transparent;
        box-shadow: 1px 5px 10px 1px transparent;
    }
}